import React from "react"
import Layout from "@components/layout"
import Meta from "@components/meta"
import * as Heading from "@components/heading"
import Timeline from "@components/timeline"
import BreadCrumbs from "../components/breadcrumbs";

const Company = () => (
  <Layout>
    <Meta
      title="当社の沿革"
      desc="当社は岡山に根差し、産業用電気機器を中心とした販売を行なう専門商社として成長を続けています。"
    />{ }

    <Heading.H1 text="当社の沿革" />
    <div className="px-5 mb-12 lg:max-w-4xl lg:mx-auto">
      <BreadCrumbs
        lists={[
          {
            string: "会社情報",
            path: "/company",
          },
          {
            string: "当社の沿革",
          }
        ]}
      />
      <Timeline values={
        [
          ['明治35年', '個人商店として綿織物の製造・販売を開始。引続き石炭・電材の卸売りをはじめる'],
          ['大正11年','資本金 150万円をもって株式会社木原商店に改組。大阪支店・今治支店開設'],
          ['大正12年','富士電機製造株式会社の創立を機に特約店として電気機器の取扱いをはじめる'],
          ['昭和19年','商号を木原興業株式会社に変更'],
          ['昭和39年','岡山本社を現在の田町に移転、隣接地にモータープールを開業'],
          ['昭和41年','資本金を 2,000万円に増資'],
          ['昭和49年','不動産の運営部門を設ける'],
          ['平成12年','岡山本社 社屋完成'],
          ['平成14年','繊維事業部門を廃止'],
          ['平成23年','ISO 9001:2008認証取得（岡山本社および倉敷営業所）'],
          ['平成24年','倉敷営業所（電機営業第1部）を岡山本社に統合'],
          ['平成27年','	ISO 9001:2015認証更新'],
          ['令和2年','健活企業 認定（全国健康保険協会）'],
          ['令和4年','倉敷営業所　開設'],
          ['令和4年','設立100周年']
        ]
      } />
    </div>
  </Layout>
)

export default Company;
import React from "react"

const Timeline = ({ values }) => {
    const tuple = values.map((item) =>
        <li className="">
            <div className="flex items-center">
                <div className="border-4 border-secondary bg-white bg-opacity-100 rounded-full h-8 w-8 z-10 mt-4"></div>
                <div className="divide-y divide-primary mt-8 flex-1 font-medium">
                    <div className="mb-2 text-primary">{item[0]}</div>
                    <div className="text-primary">{item[1]}</div>
                </div>
            </div>
        </li>
    );

    return (
        <div className="relative w-3/4 mb-8">
            <div className="border-r-2 border-gray-800 absolute h-full top-0" style={{left: '15px'}}></div>
            <ul className="list-none p-0">
                {tuple}
            </ul>
        </div>
    )
}

export default Timeline;